import { render, staticRenderFns } from "./UserCalendarSession.vue?vue&type=template&id=5b912aca"
import script from "./UserCalendarSession.vue?vue&type=script&lang=ts"
export * from "./UserCalendarSession.vue?vue&type=script&lang=ts"
import style0 from "./UserCalendarSession.vue?vue&type=style&index=0&id=5b912aca&prod&module=true&lang=scss"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppRange: require('/builds/project/volu/frontend/components/app/AppRange.vue').default,AppBtn: require('/builds/project/volu/frontend/components/app/AppBtn.vue').default,AppSessionFavoriteSwitch: require('/builds/project/volu/frontend/components/app/AppSessionFavoriteSwitch.vue').default})
